import React, { useContext } from "react";
import { SIGN_UP_PROCESS_STEP } from "../../../commons/signup.constants";
import { CustomButtonOutLine } from "../../../components/custom-button";
import ConnectBankContext from "../../../context/connect-bank-context";
import SignupContext from "../../../context/sign-up-context";
import {
  Anz,
  Boq,
  CommonWealth,
  Mac,
  Nab,
  Westpac,
} from "../../../images/index";
import { primaryColor } from "../../../scss/colors.scss";
import Plus from "../../../svgs/Plus";

const ConnectBankContainer = () => {
  const { setSignupProcess } = useContext(SignupContext);
  const { startConnectingExternalAccounts } = useContext(ConnectBankContext);

  const handleSkipButton = () => {
    setSignupProcess(SIGN_UP_PROCESS_STEP.SIGN_UP_SUCCESS);
  };

  return (
    <div className="body-context-container">
      <div className="signup-context-title">Link your bank account</div>
      <div
        className="signup-bank-logo-group"
        onClick={startConnectingExternalAccounts}
        role="button"
        tabIndex="0"
      >
        <div className="signup-bank-logo-container">
          <div className="signup-bank-logo">
            <img src={CommonWealth} alt="CommonWealth" />
          </div>
          <div className="signup-bank-logo">
            <img src={Anz} alt="ANZ" />
          </div>
          <div className="signup-bank-logo">
            <img src={Nab} alt="ANZ" />
          </div>
        </div>
        <div className="signup-bank-logo-container">
          <div className="signup-bank-logo">
            <img src={Westpac} alt="ANZ" />
          </div>
          <div className="signup-bank-logo">
            <img src={Mac} alt="ANZ" />
          </div>
          <div className="signup-bank-logo">
            <img src={Boq} alt="Boq" />
          </div>
        </div>
        <div className="select-bank">
          <div>Connect Bank</div>
          <div className="plus-icon">
            <Plus fill={primaryColor} />
          </div>
        </div>
      </div>
      <div className="signup-property-spacing" />
      <CustomButtonOutLine label="Skip" onClick={handleSkipButton} />
    </div>
  );
};

export default ConnectBankContainer;
